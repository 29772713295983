<template>
  <f7-app :params="f7params" >
<f7-appbar>
    <div class="left">
      <f7-link external href="https://maskcount.com" class="linkback"><img src="../static/icons/128x128.png" class="applogox" /> MaskCount</f7-link>
    </div>
    <div class="right">
      <f7-button v-if="!user" external href="https://app.maskcount.com"><f7-preloader v-if="checkingAuth"></f7-preloader><span v-else>Go to APP</span></f7-button>
      <f7-button v-else color="red" @click="logmeout()" fill raised>Logout</f7-button>
    </div>
  </f7-appbar>
  <!-- Right panel with reveal effect-->
  <f7-panel left cover  :visible-breakpoint="960">
    <f7-view>
      <f7-page>
        <f7-navbar> <f7-block>   <f7-chip v-if="level2" :text="level1+'-'+level2" deleteable @click="deleteChip('level2',level2)"></f7-chip> <f7-chip v-else-if="level1" :text="focus1" deleteable @click="deleteChip('level1',level1)"></f7-chip><span v-else>Locations</span></f7-block></f7-navbar>
          <f7-list class="no-margin-top">
        <f7-list-item divider title="Mask Usage"></f7-list-item>
        <f7-list-item
        v-for="country in sortedCountrx"
        :key="country.nam"
        :title="country.nam"
        :link="!country.stp && (!level1 || level1=='US')?'#':null"
        :after="country.stats.p.toFixed(2)+'%'"
        @click="selcountry(country)"
        :header="'By '+country.u+' observers'"
        :footer="'During '+country.s+' sesions'"
        :class="{tbold: uscounty && uscounty==country.id}"
     >
     <f7-icon tooltip="Map with issues" v-if="country.stp" material="location_off" slot="after"></f7-icon>
     </f7-list-item>
     <f7-list-item v-if="!sortedCountrx.length">No data for date selected</f7-list-item>
      </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>

   <f7-panel v-if="false" right cover  :visible-breakpoint="960">
    <f7-view>
      <f7-page>
        <f7-navbar title="Filters"></f7-navbar>
        <f7-list class="no-margin-top">
          <f7-list-item divider title="Indoor/Outdoor"></f7-list-item>
          <f7-list-item radio value="all" name="indooroutdoor" checked title="All"></f7-list-item>
          <f7-list-item radio value="indoor" name="indooroutdoor" title="Indoor"></f7-list-item>
          <f7-list-item radio value="outdoor" name="indooroutdoor" title="Outdoor"></f7-list-item>
          <f7-list-item divider title="Observer type"></f7-list-item>
          <f7-list-item radio value="all" name="obstype" checked title="All"></f7-list-item>
          <f7-list-item radio value="verified" name="obstype" title="Verified"></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


   <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
      <f7-page>
        <f7-navbar title="Detailed view">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block class="text-align-center">
          <p>You need admin privileges in a <f7-chip color="blue">PRO</f7-chip> team to access the detailed view.</p>
          <p>Are you and admin in a <f7-chip color="blue">PRO</f7-chip> team? if so request an access code to your account email</p>
           </f7-block>
          <f7-block>
          <f7-list no-hairlines-md>
            <f7-list-input
            v-if="!coderequested"
              label="e-mail"
              type="email"
               :value="emailtoRequest"
              @input="emailtoRequest = $event.target.value"
              @keyup.native.enter="requestCode()"
              placeholder="account email"
              outline
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              v-else
              label="CODE"
              type="text"
              :value="codetocheck"
              @input="codetocheck = $event.target.value"
              placeholder="CODE received"
              @keyup.native.enter="validateCode()"
              outline
              clear-button
            >
            </f7-list-input>
          </f7-list>
          <f7-button v-if="!coderequested" @click="requestCode()" raised large outline>Request CODE</f7-button>
          <f7-block  v-else>
            <f7-row>
               <f7-col><f7-button  @click="coderequested = false" color="gray"  raised large outline>Back</f7-button></f7-col>
              <f7-col><f7-button :class="{disabled:!codetocheck}" @click="validateCode()"  raised large fill>Authenticate</f7-button></f7-col>
            </f7-row>
          </f7-block>
          
        </f7-block>
      </f7-page>
    </f7-popup>


  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  import { mapState} from 'vuex';

  export default {
    data() {
      return {
        // Framework7 Parameters
        calendarRange: null,
        f7params: {
          name: 'MK - Dashboard', // App name
          theme: 'md', // Automatic theme detection
          // App routes
          routes: routes,
        },
        popupOpened: false,
        coderequested: null,
        emailtoRequest:'',
        codetocheck:'',
        user: null,
        checkingAuth: true,
        oreuscounty: null

      }
    },
    computed:{
      ...mapState(['countrieslist','dev','level1','level2','focus1','uscounty']),
      sortedCountrx(){
        let arryado = Object.values(this.countrieslist) || [];
        let neoarreglado = arryado.map(unteam=>{
              unteam.low = unteam.nam.toLowerCase();
              return unteam;
            });
         neoarreglado.sort((a, b) => {
                  if(a.low > b.low){
                      return 1
                  }
                  else if(a.low < b.low){
                      return -1
                  }
                  else{
                    return 0
                  }
              });
        return neoarreglado;
      }
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
        //rangedatx
        this.$firebase.auth().onAuthStateChanged((user) => {
          this.user = user;
         
          if(user){
            this.getMyProTeams();
          }
          else{
             this.checkingAuth = false;
            this.cehckInfoxr();
          }
        });

      });
    },
    methods:{
      cehckInfoxr(){
        let url = new URL(window.location.href);
        let al1 = url.searchParams.get('al1');
         let al2 = url.searchParams.get('al2');
        if(al1){
          console.log('recibe al1',al1);
          //al2
          //eliminar
          window.history.replaceState({}, document.title, '/');
           this.$store.commit('setWhatTo',{what:'level1',to: al1});
           if(al2){
               this.$store.commit('setWhatTo',{what:'level2',to: al2});
           }
        }
      },
      async getMyProTeams(){
        console.log('trantado de llamar mis teams',`privx/${this.user.uid}/teams`)
        try {
          let myteamsrpo = await this.$firebase.database().ref(`privx/${this.user.uid}/teams`).once('value');
          let teams={};
          let teamquerirs=[];
          if(myteamsrpo.exists()){
            let misteams = myteamsrpo.val();
            console.log('misteams',misteams)
            myteamsrpo.forEach(potsnap => {
              if(potsnap.val()=='admin'){
                teamquerirs.push( this.$firebase.database().ref(`teams/${potsnap.key}`).once('value') );
              }
            });

            let multisonaptmas = await Promise.all(teamquerirs);
            multisonaptmas.forEach(ptonsap => {
              if(ptonsap.exists()){
                let teamval = ptonsap.val();
                if(teamval.exp){
                  teams[teamval.id]=teamval.name;
                }
              }
            });
            this.$store.commit('setWhatTo',{what:'myteams',to:teams});
          }
          else{
            console.log('noexiste?',myteamsrpo.val())
          }
           this.checkingAuth = false;
          this.cehckInfoxr();
        } catch (error) {
          return this.$f7.dialog.alert(error.message,error.code);
        }
      },
      logmeout(){
        this.checkingAuth = true;
          return this.$firebase.auth().signOut().then(()=> {
          // Sign-out successful.
          console.log('Good bye');
          this.$f7.dialog.preloader('Closing session...')
           window.location.reload();
          this.checkingAuth = false;
        }).catch((error) => {
          // An error happened.
          console.log(error, 'error logging out');
          this.checkingAuth = false;
          this.$f7.dialog.alert(error.message, error.code)
        });
      },


      validateCode(){
        console.log('validating code');
         this.$f7.dialog.preloader('Validating');
          let validateCodeDashx = this.$firebase.functions().httpsCallable('validateCodeDashx');
            return  validateCodeDashx({email: this.emailtoRequest, code: this.codetocheck, dev: this.dev }).then((result)=> {
              if(result.data.error){
                throw new Error(result.data.error)
              }
              console.log('token recibido OK',result.data.ct);
              return this.$firebase.auth().signInWithCustomToken(result.data.ct)
            }).then(userCredential=>{
             this.user = userCredential.user;
             this.popupOpened = false;
              this.$store.commit('setWhatTo',{what:'uscounty',to: this.oreuscounty})
               this.$f7.dialog.close();
            }).catch(error=>{
              this.$f7.dialog.close();
              console.log(error);
              this.$f7.dialog.alert(error.message || error, error.code || 'error')
            });
      },
      requestCode(){
        this.$f7.dialog.preloader('Requesting');
          let rekcodedash = this.$firebase.functions().httpsCallable('rekcodedash');
            return  rekcodedash({email: this.emailtoRequest, dev: this.dev }).then((result)=> {
              if(result.data.error){
                throw new Error(result.data.error)
              }
                this.$f7.dialog.close();
              console.log('Codigo pedido OK');
              this.coderequested = this.emailtoRequest;
              return this.$f7.dialog.alert('Please check your inbox and spam folder for a Code from @maskcount.com','Code generated')
            }).catch(error=>{
              if(error.message=='exs'){
                error='There is a still valid code generated for this email. Please check your inbox and spam folder';
                this.coderequested = this.emailtoRequest;
              }
              this.$f7.dialog.close();
              console.log(error);
              this.$f7.dialog.alert(error.message || error, error.code || 'error')
            });
      },
      selcountry(contr){
        console.log(contr)
        if(!this.level2){
          if(!this.level1 && !contr.stp){
            //guardar nombre en foco
            this.$store.commit('setWhatTo',{what:'focus1',to: contr.nam});
            this.$store.commit('setWhatTo',{what:'level1',to: contr.id});
          }
          else if(this.level1 && this.level1=='US' && !contr.stp){
            this.$store.commit('setWhatTo',{what:'level2',to: contr.id});
          }
          // else{
          //   this.$store.commit('setWhatTo',{what:'level1',to: contr.id});
          // }
        }
        else if(this.level2 && this.level1 =='US'){
          if(this.user){
             this.$store.commit('setWhatTo',{what:'uscounty',to: contr.id})
              console.log('Estamos en este nivel',this.level1, this.level2,contr)
          }
          else{
            this.oreuscounty = contr.id;
            this.popupOpened = true;
          }
        }
      },
      deleteChip(level,detail){
         this.$store.commit('setWhatTo',{what:level,to: null});
         this.$store.commit('setWhatTo',{what:'uscounty',to: null});
        console.log('deleting chip',level,detail)
      }
    }
  }
</script>
<style>

.appbar{
    background-color: #3fafbd;
}
.appbar .linkback{
   color: #08253d!important;
  font-weight: bold;
  font-size: 24px;
}
.appbar .button{
  background-color: #08253d;
  font-weight: bold;
    border-radius: 10px;
    color:#FFF;
    padding-right: 20px;
     padding-left: 20px;
}
.appbar .button.color-red{
  background-color: rgb(189, 0, 0)!important;
}
.appbar .appbar-inner{
  padding-left:0px!important;
}
.applogox{
  height: 48px;
  transform: scaleX(-1);
}
.tbold .item-title{
  font-weight: bold!important;
}
.tbold{
  background:#e4fcff;
}
</style>