<template>
  <f7-page class="backergris" name="home">
    <!-- Top Navbar -->
    <f7-navbar >
      <f7-nav-left>
        <f7-link  icon-material="menu" panel-open="left"></f7-link>
         <div class="list no-margin no-hairlines-md calwidth">
        <ul>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Date Range</div>
                <div class="item-input-wrap">
                  <input type="text" placeholder="Select date range" readonly="readonly" id="rangedatx"/>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <f7-link popover-open=".popover-menu" icon-material="date_range"></f7-link>
      </f7-nav-left>

      <f7-nav-right v-if="false">
        <f7-link  icon-material="filter_list" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
      <f7-block v-if="gettingData"><f7-preloader></f7-preloader> Fetching data...</f7-block>
      <f7-block v-else> <f7-link popover-open=".popover-infox"><f7-icon class="margin-right-half" material="info"></f7-icon> V. {{$store.state.appVersion}}</f7-link></f7-block>
      <f7-block v-if="$f7.device.desktop">&copy; 2020 Regenstrief Institute</f7-block>
    </f7-toolbar>
    <!-- Page content-->






  <f7-row>
    <f7-col width="100" :class="$f7.device.desktop?'worldmap':'worldmapmob'">
      <f7-card class="margin" >

    <f7-card-header><span>Mask Usage: {{level2?level1+'-'+level2:level1?this.focus1:'World'}} {{uscounty?`-${uscounty}`:''}}</span><span></span></f7-card-header>
    <f7-card-content :class="$f7.device.desktop?'cardon':'cardonmob'" :padding="false">
        <div v-show="uscounty" class="countymaps al100 text-align-center">
        <f7-preloader class="margin-top"></f7-preloader><br/>Loading map...
      </div>
      <GChart
      v-if="!level1 && !level2 && !uscounty"
    :settings="{
      packages: ['geochart'],
      'mapsApiKey': 'AIzaSyAeMoec1xjigaXju_c2_KK3AjMSu2Luk2Y'
    }"
    type="GeoChart"
    :data="chartData"
    :options="chartOptions"
    :events="chartEvents"
    @ready="onChartReady"
    ref="gChart"
  />
   <GChart
      v-else-if="level1 && !uscounty"
    :settings="{ 
      packages: ['geochart'],
      'mapsApiKey': 'AIzaSyAeMoec1xjigaXju_c2_KK3AjMSu2Luk2Y'
    }"
    type="GeoChart"
    :data="chartData"
    :options="countrychartop"
    ref="gChart"
  />

  </f7-card-content>
  <f7-card-footer v-if="!uscounty && chartData.length>1" class="no-border">
    <f7-block>Min: {{byCountriesStats.cmin?allcountriesdata[byCountriesStats.cmin].nam:''}} ({{byCountriesStats.min}}%)</f7-block>
    <f7-block>Median: ({{byCountriesStats.mdn.toFixed(2)}}%)</f7-block>
    <f7-block>Max: {{byCountriesStats.cmax?allcountriesdata[byCountriesStats.cmax].nam:''}} ({{byCountriesStats.max}}%)</f7-block>
  </f7-card-footer>
  <f7-card-content v-else-if="uscounty">
    <f7-row>
      <f7-col>
  <f7-list media-list>
      <f7-list-item :header="`Geohash precision: ${laprecision}`">
         <f7-range
         slot="subtitle"
            :min="3"
            :max="7"
            :step="1"
            :value="laprecision"
            @range:change="onprecisionChante"
            color="orange"
          ></f7-range>
      </f7-list-item>
    </f7-list>
      </f7-col>
      <f7-col>
        <f7-list>
          <f7-list-item  header="Data Source">
          <f7-input
          slot="title"
            type="select"
            defaultValue=""
            outline
            :value="teamid"
            @input="changeTeamSource($event.target.value)"
          >
            <option value="">All citizen scientists</option>
            <option v-for="(teamname,idx) in myteams" :key="idx" :value="idx">{{teamname}}</option>
          </f7-input>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-card-content>
</f7-card>
    </f7-col>
  </f7-row>
  <f7-row>
     <f7-col width="100" medium="60">
      <f7-card class="margin horuly" >
        <f7-card-header><span>Hourly % mask usage</span></f7-card-header>
        <bar-chart :chart-data="datacollection" :options="chartOptionsvch" :styles="myStyles"></bar-chart>
      </f7-card>
     </f7-col>
     <f7-col width="100" medium="40">
      <f7-card class="margin horuly" >
        <f7-card-header><span>Indoor v/s Outdoor mask usage</span></f7-card-header>

        <bar-chart :chart-data="dataindooroutdoor" :options="chartOptionsvch" :styles="myStyles"></bar-chart>
      </f7-card>
     </f7-col>
  </f7-row>
   <f7-row>
     <f7-col width="100">
      <f7-card class="margin horuly" >
        <f7-card-header><span>Overall Mask usage over time</span></f7-card-header>
        <line-chart :chart-data="dataovertime" :options="chartOptionline" :styles="myStyles"></line-chart>
      </f7-card>
     </f7-col>
  </f7-row>
<f7-popover @popover:opened="popovclose=true " :opened="popovclose" class="popover-menu">
    <f7-list>
      <f7-list-item radio value="today" :checked="selrange === 'today'" @change="selrangeset($event.target.value)" name="calradio"  title="Today"></f7-list-item>
      <f7-list-item radio value="last3" :checked="selrange === 'last3'" @change="selrangeset($event.target.value)" name="calradio"  title="Last 3 days"></f7-list-item>
      <f7-list-item radio value="lastweek" :checked="selrange === 'lastweek'" @change="selrangeset($event.target.value)" name="calradio"  title="Last week"></f7-list-item>
      <f7-list-item radio value="last2week" :checked="selrange === 'last2week'" @change="selrangeset($event.target.value)" name="calradio"  title="Last 2 weeks"></f7-list-item>
      <f7-list-item radio value="all" :checked="selrange === 'all'" @change="selrangeset($event.target.value)" name="calradio"  title="All data"></f7-list-item>
      <f7-list-item radio value="custom" :checked="selrange === 'custom'" @change="selrangeset($event.target.value)" name="calradio" title="Custom range"></f7-list-item>
    </f7-list>
  </f7-popover>
<f7-popover class="popover-infox">
    <f7-block>
          This dashboard contains high-level data on the mask-wearing as collected by MaskCount "Citizen Scientists."<br/>
Use the calendar icon above to select pre-set or custom date ranges, and choose a country from the menu on the left to view that country’s activity in more detail. Hovering or tapping on a colored element on the maps or graphs will reveal more details. <br/>
More details of mask-wearing counts down to the street level are available for MaskCount users. Register and login to the MaskCount app  to view maps in the “Around Me” section. 
        </f7-block>
  </f7-popover>
  </f7-page>
</template>
<script>
import { GChart } from 'vue-google-charts'
import { mapState} from 'vuex'
import BarChart from '../components/BarChart.js'
import LineChart from '../components/LineChart.js'
import loadGoogleMapsApi from "load-google-maps-api";


export default {
  components: {
    GChart,
    BarChart,
    LineChart
  },
  data(){
    return {
       timeouter:null,
       googleMaps: null,
        countyfocus:null,
        contyGeoJson:null,
        rectangles:{},
        rectamarkers:{},
        infowindows:{},
        laprecision: 5,
        teamid:null,
        procesexd:{},
        infoboxselected:{
        k:{m:0,n:0,p:0},
        i:{m:0,n:0,p:0},
        o:{m:0,n:0,p:0},
        h:{c:'',p:0,t:0},
        ho:{c:'',p:0,t:0},
        hi:{c:'',p:0,t:0},
        u:0
        },
        ///legacy abajo
      popovclose: false,
      selrange:'last2week',
      calendarRange:null,
      lemapa:null,
      mapdatarx:null,
      gettingData: true,
      totalObs:0,
      totSessions:0,
      allcountriesdata:{},
      byCountriesStats:{
        cmin:'',
        cmax: '',
        min: '-',
        max: '-',
        avg: 0,
        mdn: 0
      },
       chartData: [
          ['Country', 'MaskWearing']
        ],
      chartOptions: {
        height: this.$f7.device.desktop?400:250,
        colorAxis:{minValue: 0},
        colors: ['#ff1919','#fd481f','#fb7624','#f9a52a','#f7d32f','#a4e23d','#51f14b','#29f6a5','#15f9d2','#00fbff'],
      },
      chartEvents: {
        'select': () => {
          // handle event here
          const elmap = this.$refs.gChart.chartObject;
          const selection = elmap.getSelection();
          console.log('seleccionado el mapa',selection);
          let selc=selection[0]
        }
      },//vuehcarts
      datacollection: {},
      dataindooroutdoor:{},
      dataovertime:{},
      chartOptionsvch:{
        legend:{
          display: false
        },
        layout: {
            padding: {
                top: 10,
                left: 20,
                right: 20,
                bottom: 10
            }
        },
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true,
                  ticks:{
                  suggestedMax: 100,
                  suggestedMin: -100
                }
            }]
        },
        responsive:true,
        maintainAspectRatio: false
      },
      chartOptionline:{
        legend:{
          display: false
        },
        layout: {
            padding: {
                top: 10,
                left: 20,
                right: 20,
                bottom: 10
            }
        },
        scales: {
          xAxes: [{
                type: 'time',
                time: {
                    unit: 'day'
                }
            }]
        },
        responsive:true,
        maintainAspectRatio: false
      },
      myStyles:{
        height: '300px',
        position: 'relative'
      },
      hourslabel: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
    }
  },
  computed:{
   ...mapState(['level1','level2','uscounty','focus1','dev','myteams']),
   countrychartop(){
     return Object.assign({},this.chartOptions,
     {region: this.level2?`${this.level1}-${this.level2}` :this.level1,
      displayMode: 'markers',
      resolution: 'provinces'});
   }
  },
  watch:{
    level1(neoal){
      console.log('nuevo country '+neoal+', llamar');
      this.modulardorQuerries();
    },
    level2(neoal){
      console.log('nuevo level2 '+neoal+', llamar');
      this.modulardorQuerries();
    },
     uscounty(neoal){
      console.log('nuevo uscounty '+neoal+', llamar');
      if(!neoal){
        this.teamid=null;
      }
      this.modulardorQuerries();
    }
  },
  mounted(){
     this.calendarRange = this.$f7.calendar.create({
          inputEl: '#rangedatx',
          rangePicker: true,
          dateFormat: 'M dd yyyy', 
          closeOnSelect: true, 
          disabled:[{from: new Date()},{to: new Date(2020, 8, 12)}],
          value:[ this.$moment().subtract(2, 'w').toDate(), new Date()],
          on: {
            closed: ()=> {
              console.log('Calendar closed')
              this.callData();
            }
          }
        });
  },
  methods:{
      modulardorQuerries(){
      console.log('modulando')
      if(this.timeouter){
        clearTimeout(this.timeouter);
      }
      this.timeouter = setTimeout(()=>{
        this.callData();
         }, 100);
    },
    changeTeamSource(teamid){
      this.teamid = teamid;
      this.callData(false);
    },
      onprecisionChante(valorx){
        console.log('precision changed',valorx);
        this.laprecision = valorx;
        this.callData(true);
      },
      pintaGeoboxes(){
        //borrar recantulgos viejos
         //para cada vieja, si NO esta en nueva borrar?
        let arregloDeViejas = Object.keys(this.rectangles) || [];
        arregloDeViejas.forEach(rectkey => {
              this.rectangles[rectkey].setMap(null);
              this.rectangles[rectkey] = null;
              delete this.rectangles[rectkey]
              //ahora borrar markers tambien
              this.rectamarkers[rectkey].setMap(null);
              this.rectamarkers[rectkey] = null;
              delete this.rectamarkers[rectkey]
              //borrar infowindows
              this.infowindows[rectkey]=null;
              delete this.infowindows[rectkey]
        });
        this.rectangles={};
        this.rectamarkers={};
        this.infowindows = {};

        //computar el polygono
        // let unik = this.mapdatarx.getFeatureById(1)
        // let gaGeom = unik.getGeometry()
        // let poly = new this.googleMaps.Polygon({paths:gaGeom.getAt(0).getArray()})



        let arregloDeNuevas = Object.values(this.procesexd);
        arregloDeNuevas.forEach(unbox => {
          if(!this.rectangles[unbox.id]){
            this.dibujarec(unbox);
          }
        });
      },
    loadMap(){
      //primero crear el mapa y centrarme
          if(google && google.maps){
            this.googleMaps=google.maps;
            console.log('ya habia google maps');
            this.nowDrawMap();
          }
          else{
            loadGoogleMapsApi({key:'AIzaSyAeMoec1xjigaXju_c2_KK3AjMSu2Luk2Y',libraries:['visualization']}).then((googleMaps)=> {
              this.googleMaps=googleMaps;
              this.nowDrawMap();
              }).catch((error)=> {
              this.$f7.dialog.alert(error.message,error.code);
                console.log(error)
              });
          }
      },
  

      nowDrawMap(){
        if(!this.lemapa){
            this.lemapa= new this.googleMaps.Map(document.querySelector('.countymaps'), {
              center: {lat: this.countyfocus.ctr.lat, lng: this.countyfocus.ctr.lng},
              zoom: 10,
              maxZoom: 18,
              streetViewControl: false,
              
              mapTypeControl: false,
               fullscreenControl: false,
               restriction: {
                  latLngBounds: {
                      north: 85,
                      south: -85,
                      west: -180,
                      east: 180
                  },
                  strictBounds: true
              },
            });
        }
        else{
          console.log('actualiza posicion');
           this.lemapa.setCenter({lat: this.countyfocus.ctr.lat, lng: this.countyfocus.ctr.lng});
        }

        //borrar antiguoif any
        if(this.mapdatarx){
          this.mapdatarx.setMap(null) ;
          this.mapdatarx = null;
        }
         

        //drawnejson
       
        let pregejson = {
        "type": "FeatureCollection",
        "id": 1,
        "features": [
          {
            "type": "Feature",
            "geometry": {
              "coordinates": this.contyGeoJson.shape.coordinates,
              "type": this.contyGeoJson.shape.type
            },
            "properties": {
              "name": this.contyGeoJson.name
            }
          }
        ]
      };
    
        this.mapdatarx = new this.googleMaps.Data();

        this.mapdatarx.addGeoJson(pregejson);
          this.mapdatarx.setStyle({
            fillColor: "green",
            fillOpacity: 0,
            strokeWeight: 1,
          });
        this.mapdatarx.setMap(this.lemapa) ;

          //pintar geohashes
            //paracada nueva, si NO esta en vieja, dibujar
              this.pintaGeoboxes();
      },

   
     
       dibujarec(box){
        console.log('BLOXIN',box)
        let perc = this.toFixedNumber(box.h.p,0);
        console.log('[er',perc)
          this.rectangles[box.id] = new this.googleMaps.Rectangle({
              strokeColor: box.h.c,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: box.h.c,
              fillOpacity: 0.35,
              map: this.lemapa,
              bounds: {
                north: box.bnd[2],
                south: box.bnd[0],
                east: box.bnd[3],
                west: box.bnd[1]
              }
            });

           this.rectamarkers[box.id] = new this.googleMaps.Marker({
              position: this.rectangles[box.id].getBounds().getCenter(),
               map: this.lemapa,
               icon: '../static/img/'+box.h.c.substring(1)+'.png',
               label: {text: `${perc}%`, color: 'white',fontSize: '12px',fontWeight:'bolder'}
            });
            this.infowindows[box.id] = new this.googleMaps.InfoWindow({
              content: box.h.t+' observations <br/> by '+box.u+' observers',
            });
          this.rectamarkers[box.id].addListener("click", () => {
            if(this.lastinfoON && this.infowindows[this.lastinfoON]){
              this.infowindows[this.lastinfoON].close();
            }
           console.log('cliceado ',box);
            this.infoboxselected = Object.assign({},box);
            this.infowindows[box.id].open(this.lemapa, this.rectamarkers[box.id]);
            this.lastinfoON=box.id;
            this.infowindows[box.id].addListener("closeclick", () => {
              console.log('cerraron esta caja');
               this.resetlastinf();
               this.lastinfoON = null;
            });
          });
      },
        toFixedNumber(num, digits, base){
      let pow = Math.pow(base||10, digits);
      return Math.round(num*pow) / pow;
    },
       resetlastinf(){
    this.infoboxselected = Object.assign({},
      {
        k:{m:0,n:0,p:0},
        i:{m:0,n:0,p:0},
        o:{m:0,n:0,p:0},
        h:{c:'',p:0,t:0},
        ho:{c:'',p:0,t:0},
        hi:{c:'',p:0,t:0},
        u:0
      });
    },
    ///aqui abajo es legacy OK
    selrangeset(neoval){
      this.popovclose = false;
      this.selrange = neoval;
      if(neoval=='custom'){
        this.opencal()
      }
      else{
        if(neoval=='today'){
          this.calendarRange.setValue([new Date(),new Date()]);
        }
        else if(neoval=='last3'){
          this.calendarRange.setValue([this.$moment().subtract(2, 'd').toDate(),new Date()]);
        }
        else if(neoval=='lastweek'){
          this.calendarRange.setValue([this.$moment().subtract(1, 'w').toDate(),new Date()]);
        }
        else if(neoval=='last2week'){
          this.calendarRange.setValue([this.$moment().subtract(2, 'w').toDate(),new Date()]);
        }
        else if(neoval=='all'){
          this.calendarRange.setValue([new Date(2020, 8, 12),new Date()]);
        }
        this.callData();
      }
      
    },
    opencal(){
      this.calendarRange.open();
    },
    onChartReady(){
      console.log('gchart ready');
      this.callData();
    },
    callData(onlyHashes=false){
        let calval = this.calendarRange.getValue();
        let from = calval[0]?this.$moment(calval[0]).format('YYYYMMDD'):this.$moment().format('YYYYMMDD');
        let to =  calval[1]?this.$moment(calval[1]).format('YYYYMMDD'):from;
        this.gettingData = true;
       this.$f7.dialog.preloader('Loading...');
        let dashfeed_neo = this.$firebase.functions().httpsCallable('dashfeed_neo');
            return  dashfeed_neo({rutafrom: from, rutato: to, dev: this.dev, level1: this.level1, level2: this.level2, uscounty: this.uscounty, tid: this.teamid, laprecision: this.laprecision, onlyHashes }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          //cargar data para pintar el mapa
          if(this.uscounty){
            if(respoesta.boundariesInfo){
              this.countyfocus=Object.assign({},respoesta.boundariesInfo.linfo);
              this.contyGeoJson = Object.assign({},respoesta.boundariesInfo.pregeo);
            }
            //cargar geohashinfo if any
            if(respoesta.coreGeoHasReturner){
              this.procesexd=Object.assign({},respoesta.coreGeoHasReturner.procesexd);
            }
            else{
              this.procesexd=Object.assign({});
            }
            //ahora encender el mapa
            this.loadMap();
          }


          let byCountriesArray = respoesta.byCountriesArray || {};
          let arrayado = Object.values(byCountriesArray) || [];
          this.allcountriesdata = Object.assign({},respoesta.byCountries);
          let arrayedCountries = Object.values(respoesta.byCountries) || [];
            arrayedCountries.sort((a, b) => {
                  if(a.stats.p < b.stats.p){
                      return 1
                  }
                  else if(a.stats.p > b.stats.p){
                      return -1
                  }
                  else{
                    return 0
                  }
              });
            if(!this.uscounty){
             this.$store.commit('setWhatTo',{what:'countrieslist',to: arrayedCountries});
            }

         
            if(this.level1 && this.level1!='US'){
               arrayado.unshift(['Location', 'MaskWearing','Observations']);
            }
            else if(this.level1 && this.level1=='US'){
               arrayado.unshift(['State', 'MaskWearing','Observations']);
            }
            else{
               arrayado.unshift(['Country', 'MaskWearing']);
            }
           if(!Object.values(byCountriesArray).length){
              arrayado[0].pop();
          }
          this.chartData=arrayado;

          console.log('grafico pintado con ', this.chartData);


            this.byCountriesStats = respoesta.byCountriesStats;
            this.totalObs = respoesta.totalObs;
             this.totSessions = respoesta.totSessions;
             //horario
             let conmask = [];
            let sinmask = [];
            let partialmask = [];
             this.hourslabel.forEach(unahora => {
                if(!respoesta.byHours[unahora]){
                  conmask.push(0);
                  sinmask.push(0);
                  partialmask.push(0);
                }
                else{
                  conmask.push(respoesta.byHours[unahora].stats.p.toFixed(2));
                  partialmask.push(-(respoesta.byHours[unahora].stats.par.toFixed(2)));
                  sinmask.push(-(respoesta.byHours[unahora].stats.nom.toFixed(2)))
                }
             });
                this.datacollection = {
                  labels: this.hourslabel,
                  datasets: [
                    {
                      label: 'Mask',
                      backgroundColor: '#3fafbd',
                      data: conmask
                    },
                    {
                      label: 'Partial Mask',
                      backgroundColor: '#ffcc00',
                      data: partialmask
                    },
                    {
                      label: 'No Mask',
                      backgroundColor: '#ff6000',
                      data: sinmask
                    }
                  ]
                }
             //fin horario

             //indooroudoor
              let indoout = respoesta.byinout || {};
              this.dataindooroutdoor = {
                  labels: ['Indoor','Overall','Outdoor'],
                  datasets: [
                    {
                      label: 'Mask',
                      backgroundColor: '#3fafbd',
                      data: [ indoout.i.stats.e?null:indoout.i.stats.p.toFixed(2),indoout.all.stats.e?null:indoout.all.stats.p.toFixed(2),indoout.o.stats.e?null:indoout.o.stats.p.toFixed(2)]
                    }, {
                      label: 'Partial Mask',
                      backgroundColor: '#ffcc00',
                      data: [indoout.i.stats.e?null:(-(indoout.i.stats.par.toFixed(2))),indoout.all.stats.e?null:(-(indoout.all.stats.par.toFixed(2))),indoout.o.stats.e?null:(-(indoout.o.stats.par.toFixed(2)))]
                    },
                    {
                      label: 'No Mask',
                      backgroundColor: '#ff6000',
                      data: [indoout.i.stats.e?null:(-(indoout.i.stats.nom.toFixed(2))),indoout.all.stats.e?null:(-(indoout.all.stats.nom.toFixed(2))),indoout.o.stats.e?null:(-(indoout.o.stats.nom.toFixed(2)))]
                    }
                  ]
                }
             //fin indooroutdoor

             //overtime
            let fechas = respoesta.byDates || {};
            let fechasArray = Object.keys(fechas) || [];
            let datapoints=[];
            fechasArray.forEach(unafech => {
              datapoints.push({x: unafech, y: fechas[unafech].stats.p.toFixed(2)});
            });
            this.dataovertime={
              datasets:[
                {
                  label: '% Mask usage',
                   backgroundColor: '#3fafbd',
                  data:datapoints
                }
              ]
              };
             //fin over time
           this.gettingData = false;
            this.$f7.dialog.close();
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
        }).catch(error=>{
           this.gettingData = false;
            this.$f7.dialog.close();
          console.log(error);
            this.$f7.dialog.alert(error.message,error.code);
        })
    }
  }
}
</script>
<style>
.worldmap{
  min-height: 528px;
}
.worldmapmob{
  min-height: 278px;
}
.cardon {
  height:400px
}
.cardonmob{
  height:250px
}
.backergris .page-content{
  background: #efefef;
}
.dblock{
  display: block!important;
}
.clistx{
  max-height: 496px;
  overflow-y: auto;
}

.clistxdesl{
  min-height: 496px;
}
.horuly{
  height: 348px;;
}
.calwidth{
  width: 240px;
}
.statnum .item-media div{
  font-size: 30px;
    font-weight: bold;
    margin: 0 auto;
}
.statnum .item-media{
  width: 80px;
}
@media screen and (min-width: 960px){
  .link.icon-only.panel-open{
  display: none;
  }
}
</style>