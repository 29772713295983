import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appVersion: '1.6.0',
        activeTab:'/',
        dev: process.env.NODE_ENV=== 'production'?false:true,
        countrieslist: [],
        level1: null,
        level2: null,
        myteams:{},
        uscounty: null,
        focus1:null
    },
    getters: {
     
    },
    mutations: {
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        pushToArray: (state,payload) => {
          state[payload.array].push(payload.value);
        },
        removeFromArray: (state,payload)=>{
            let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
            if(indiexist!=-1){
                state[payload.array].splice(indiexist,1);
            }
        },
       mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        removeFromSimpleArray(state, payload){
            let index = state[payload.array].findIndex((item) =>item === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1);
            }
        },
    },
    actions:{

    }
})